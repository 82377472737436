import React, { useEffect, useState } from "react";
import "./style.css"
import {
  Box,
} from "@mui/material";

function CustomTabs(props) {
  const { children } = props;
  const [tabsHeaders, setTabsHeaders] = useState([]);
  const [contentMap, setContentMap] = useState({});
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    const headers = [];
    const map = {};

    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;
      const { title } = element.props;
      headers.push(title);
      map[title] = element.props.children;
    });

    setTabsHeaders(headers);
    setContentMap(map);
    setActiveTab(headers[0]);
  }, [children]);

  const changeTab = (header) => {
    setActiveTab(header);
  };

  return (
    <>
      <Box sx={{ 
        display: { xs: "flex", overflow:"auto" },
         background: "#EBEBEB",
         padding:"5px",
         borderRadius:"10px 10px 0 0"
         
        }}>
        {tabsHeaders.map((header) => (
          <button
            className={activeTab === header ? "selected" : "notSelected"}
            style={{
              margin: "5px",
              padding: "15px 25px",
              border: "none",
              borderRadius: "17px",
              cursor: "pointer",
              whiteSpace:"pre",
        
            }}
            key={header}
            onClick={() => changeTab(header)}
          >
            {header}
          </button>
        ))}
      </Box>
      <div>
        {Object.keys(contentMap).map((key) => {
          if (key === activeTab) {
            return <div key={key}>{contentMap[key]}</div>;
          }
          return null;
        })}
      </div>
    </>
  );
}

export default CustomTabs;
