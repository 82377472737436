import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function SlashRedirect({ children }) {
  const location = useLocation();  // Get the current location
  const navigate = useNavigate();  // For redirection

  useEffect(() => {
    // Check if the URL contains multiple slashes (more than one consecutive '/')
    if (/\/{2,}/.test(location.pathname)) {
      navigate(-1);  // Navigate back to the previous page
    }
  }, [location, navigate]);

  return children;  // Render the rest of the app if no extra slashes
}

export default SlashRedirect;
