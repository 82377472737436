import React, { useEffect } from "react";

const ClearLocalStorageOnDateChange = () => {
    useEffect(() => {
        // Execute immediately after the component mounts
        const checkAndClearLocalStorage = () => {
            const today = new Date().toDateString(); // Get today's date as a string (e.g., "Mon Sep 25 2024")
            const storedDate = localStorage.getItem("lastVisitDate"); // Retrieve the stored date

            if (storedDate !== today) {
                // Clear specific keys immediately
                localStorage.removeItem("TDFToken");
                localStorage.removeItem("loginConform");

                // Store the updated date
                localStorage.setItem("lastVisitDate", today);
            }
        };

        checkAndClearLocalStorage(); // Call the function immediately
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    return null; // No UI rendering needed
};

export default ClearLocalStorageOnDateChange;
