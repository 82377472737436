import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  TextField,
  Button,
  Modal,
  Container,
  useMediaQuery,
  Popover,
} from "@mui/material";
import {
  useNavigate, 
 
} from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from "react-router-dom";
import axiosInstance from "../service/axiosInstance";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SmsIcon from "@mui/icons-material/Sms";
import Loading from "../Components/Loading";

function SettleOffer() {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const navigate = useNavigate();
  const location = useLocation();
  const accountDetailsId = location?.state?.accountDetailsId;
  const isMorebtn = location?.state?.isMorebtn;


  const [settleData, setSettleData] = useState();
  const [amountToPaid, setAmountToPaid] = useState("");
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null); // State for popover


  const handleLogout = () => {
    localStorage.clear("experianData");
    localStorage.clear("TDFToken");
    localStorage.clear("loginConform");
    navigate("/");
  };
  const handlegoProfile = () => {
    navigate("/dashboard/profile");
  };
  const isMobile = useMediaQuery("(max-width:600px)");
const morebtn=isMorebtn ? "acDetail":"settle"

  useEffect(() => {
    const token = localStorage.getItem("TDFToken");
    axiosInstance
      .post(
        `/tdf/api/user/${morebtn}`,
        
        { accountDetailsId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSettleData(res?.data);
        setAmountToPaid(res?.data?.amountToPaid);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);
  const handlegoBack = () => {
    navigate(-1);
  };
  const handleChange = (e) => {
    setAmountToPaid(e.target.value);
  };
  const handleSubmit = () => {
    setLoading(true)
    const token = localStorage.getItem("TDFToken");
    axiosInstance.post(
      "/tdf/api/counterOffer",
      { accountDetailsId: accountDetailsId, amount: amountToPaid, phone: settleData?.accountDetails?.mobileNo },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {

      setOpenModal(true)
      setLoading(false)

    }).catch((err) => {
      setLoading(false)

    })

  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Open popover
  };

  const handleClose = () => {
    setAnchorEl(null); // Close popover
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {loading ? (<>
        <Loading />
      </>) : (<>

        <Box sx={{ padding:{xs:"20px", lg:"32px"} }}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography style={{ color: "#044a67", fontSize: "20px" }}>
                Hi {location?.state?.name} {location?.state?.surName}!
              </Typography>
              <Typography style={{ color: "#044a67", fontSize: "20px" }}>
                It's good to see you again.
              </Typography>
            </Box>
            {isMobile ? (
              <></>
            ) : (
              <>
                <Box style={{ display: "flex" }}>
                    <SettingsIcon
                      style={{ marginRight: "20px", cursor: "pointer" }}
                      onClick={handleClick} // Open the popover on click
                    />
                  <Tooltip title="Logout">
                    <LogoutIcon
                      onClick={handleLogout}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </Box>
              </>
            )}    
            
          {/* Popover for Settings */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box p={2}>
              <Typography
                onClick={handlegoProfile}
                style={{ cursor: "pointer" }}
              >
                Profile
              </Typography>
            </Box>
          </Popover>      
          </Box>
          <Grid container spacing={1} style={{ marginTop: "20px" }}>
            <Grid item xs={2} sm={4} lg={4}>
              <Box>
                <ArrowBackIcon
                  style={{ cursor: "pointer" }}
                  onClick={handlegoBack}
                />
              </Box>
            </Grid>
            <Grid item xs={10} sm={8} lg={8}>
              <Box>
                <Typography style={{ fontWeight: "600", fontSize: "20px" }}>
                  {settleData?.accountDetails?.subscriber_Name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box style={{ marginTop: "10px", display: "flex" }}>
            <SmsIcon
              style={{
                fontSize: "14px",
                marginTop: "6px",
                color: "#044a67",
                marginRight: "5px",
              }}
            />
            <Typography>{settleData?.accountDetails?.msg}</Typography>
          </Box>
          <Box style={{ marginTop: "10px", display: "flex" }}>
            <Typography style={{ fontWeight: "600", fontSize: "16px" }}>
              Loan Type :{" "}
              <span style={{ fontWeight: "600", fontSize: "16px" }}>
                {settleData?.accountDetails?.loanType}{" "}
              </span>
            </Typography>
          </Box>
          <Box style={{ marginTop: "20px" }}>
            <Typography style={{ fontSize: "20px" }}>Account Details :-</Typography>
          </Box>
          <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={3} lg={3} style={{ border: "1px solid rgba(0,0,0,.12)", padding: "10px" }}>
              <Box>Account Number</Box>
              <Box style={{overflow:"auto"}}>{settleData?.accountDetails?.account_Number}</Box>
            </Grid>
            <Grid item xs={12} sm={3} lg={3} style={{ border: "1px solid rgba(0,0,0,.12)", padding: "10px" }}>
              <Box>Total Outstanding Amount</Box>
              <Box>₹{settleData?.totalOutStanding}</Box>
            </Grid>
            <Grid item xs={12} sm={3} lg={3} style={{ border: "1px solid rgba(0,0,0,.12)", padding: "10px" }}>
              <Box>Days Overdue</Box>
              <Box>{settleData?.daysOverdue}</Box>
            </Grid>
            <Grid item xs={12} sm={3} lg={3} style={{ border: "1px solid rgba(0,0,0,.12)", padding: "10px" }}>
              <Box>Priority</Box>
              <Box>{settleData?.priority}</Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={8} lg={8}>
              <Box style={{ display: "flex" }}>
                <Typography style={{ marginTop: "15px" }}>
                  Amount to be paid
                </Typography>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  style={{ marginLeft: "10px" }}
                  value={amountToPaid}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} style={{ marginTop: "20px" }}>
              <Button
                style={{
                  background: "#044a67",
                  color: "#fff",
                  padding: "7px 15px",
                  textTransform: "capitalize",
                }}
                onClick={handleSubmit}
              >
                Submit Offer
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              // width: "640px",
              width: {
                lg: "25%",
                sm: "50%",
                xs: "90%",
              },
              // padding: "15px",
            }}
          >
            <Typography
              id="modal-title"
              variant="h6"
              component="h2"
              style={{ fontSize: "20px" }}
            >
              Thank you for making a counter offer.
            </Typography>
            <Typography
              id="modal-title"
              variant="h6"
              component="h2"
              style={{ fontSize: "16px" }}
            >
              Please allow us to revert to
              you within 48 hours.
            </Typography>
            <Box style={{ display: "flex", alignSelf: "self-end" }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCloseModal}
                style={{
                  background: "#05517a",
                  color: "white",
                  borderRadius: "10px",
                  marginTop: "20px",
                }}
              >
                Close
              </Button>
            </Box>
          </Container>
        </Modal>
      </>)}

    </>
  );
}

export default SettleOffer;
