import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from "react-router-dom";

const HelmetWrapper = () => {
  const location = useLocation();

  // Define meta title based on the path
  const getTitle = (path) => {
    switch (path) {
      case "/login":
        return "Due Factory | Log in";
      case "/AboutUs":
        return "Due Factory | About Us";
      case "/contact-us":
        return "Due Factory | Contact Us";
      case "/terms-&-conditions":
        return "Due Factory | Terms & Conditions";
      case "/faq":
        return "Frequently Asked Questions - Due Factory";
      default:
        return "Due Factory - Credit Score Improvement | Credit Repair | Debt Relief Platform";
    }
  };

  // Default description for all pages
  const description =
    "Check credit score, Settle your old outstanding loans, follow our recommendations and boost credit score with Due Factory. Pay in full or in parts.";

  // Get the current path's title
  const title = getTitle(location.pathname);

  return (
    <HelmetProvider>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
    </HelmetProvider>
  );
};

export default HelmetWrapper;
