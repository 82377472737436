import React from 'react'

function SAccountsCard({creditMixdata }) {
    return (
        <>
            <div className='SecuredCard'>

                <div className='totalamtCard'>
                    <div className='textcardDiv' >
                        <div className='card-details-div-1'>
                            <div>Total Amount : </div>
                            <div>Total Outstanding : </div>
                            <div>Total Amount Pass Due : </div>
                        </div>
                        <div className='card-details-div-2'>
                        <div>₹{creditMixdata?.totalUnsecuredAmount?.toLocaleString('en-IN')}</div>
                            <div>₹{creditMixdata?.outstandingUnsecureAmount.toLocaleString('en-IN')}</div>
                            <div>₹{creditMixdata?.unsecureAmountPastDue.toLocaleString('en-IN')}</div>
                        </div>
                    </div>
                </div>

                <div className='Accinfo'>
                    <div className='acctiveAccounts'>
                        <div className='text-account-num'>{creditMixdata?.unsecureActiveAccounts}</div>
                        <div className='text-account-text' >Active Accounts</div>
                    </div>

                    <div className='closedAccounts'>
                        <div className='text-account-num'  >{creditMixdata?.unsecureClosedAccounts}</div>
                        <div className='text-account-text' >Closed Accounts</div>
                    </div>



                </div>

            </div>
        </>
    )
}

export default SAccountsCard