
import React from 'react';
import ZingChart from 'zingchart-react';
import 'zingchart/es6';



const GaugeChart = ({score}) => {
  const gaugeConfig = {
    graphset: [
      {
        type: 'gauge',
        globals: {
          fontSize: 25,
        },
        plotarea: {
          marginTop: 50,
          marginBottom: 0,   
      
        },
        branding:false,
        plot: {
          size: '100%',
          valueBox: {
            placement: 'center',
            text: '%v',
            fontSize: 18,
            rules: [
              {
                rule: '%v',
                text: '%v<br> Out of 900',
              },
              {
                rule: '%v < 500',
                text: '%v<br>Out of 900<br>Very Poor',
              },
              {
                rule: '%v >= 500 && %v < 600',
                text: '%v<br>Out of 900<br>Poor',
              },
              {
                rule: '%v >= 600 && %v < 700',
                text: '%v<br>Out of 900<br>Fair',
              },
              {
                rule: '%v >= 700 && %v < 800',
                text: '%v<br>Out of 900<br>Good',
              },
              {
                rule: '%v >= 800',
                text: '%v<br>Out of 900<br>Excellent',
              },
            ],
          },
        },
        tooltip: {
          borderRadius: 5,
        },
        scaleR: {
          aperture: 250,
          minValue: 300,
          maxValue: 900,
          step: 20,
          center: {
            visible: false,
          },
          tick: {
            visible: false,
          },
          item: {
            offsetR: 0,
            offsetX: 0,
            offsetY: 0,
          },
          labels: [
            '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '','','','','',
          ],
          ring: {
            size: 5,
            rules: [
              {
                rule: '%v < 400',
                backgroundColor: '#F22424',
              },
              {
                rule: '%v >= 400 && %v < 420',
                backgroundColor: '#F22424',
              },
              {
                rule: '%v >= 420 && %v < 520',
                backgroundColor: '#FE670A',
              },
              {
                rule: '%v >= 520 && %v < 540',
                backgroundColor: '#FE670A',
              },
              {
                rule: '%v >= 540 && %v < 640',
                backgroundColor: '#FED000',
              },
              {
                rule: '%v >= 640 && %v < 660',
                backgroundColor: '#FED000',
              },
           
              {
                rule: '%v >= 660 && %v < 760',
                backgroundColor: '#34C637',
              },
              {
                rule: '%v >= 760 && %v < 780',
                backgroundColor: '#34C637',
              },
              {
                rule: '%v >= 780',
                backgroundColor: '#008042',
              },
            ],
          },
        },
        series: [
          {
            values: [score && score],
            backgroundColor: 'black',
            indicator: [5, 5, 0, 0, 0.8],
            animation: {
              effect: 2,
              method: 1,
              sequence: 4,
              speed: 10000,
            },
          },
        ],
        gui: {
          contextMenu: {
            button: {
              visible: false,
            },
          },
        },
      },
    ],
  };
  return <ZingChart id="gaugeChart" data={gaugeConfig}   height="200px"  />;
};

export default GaugeChart;
