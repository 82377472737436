import React, { useState } from "react";
import { Box, Button, IconButton, Popover, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logo from "../assets/imgs/DUE-FACTORY.png";
import whiteLogo from "../assets/imgs/DUE-FACTORY-white-logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import Face6Icon from "@mui/icons-material/Face6";
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import QuizIcon from "@mui/icons-material/Quiz";
import ArticleIcon from '@mui/icons-material/Article';

function HeaderComponent() {
  const [anchorEl, setAnchorEl] = useState(null);
  const loginConform = localStorage.getItem("loginConform");
  const navigate = useNavigate();
  const token = localStorage.getItem("TDFToken");
  const handleLogout = () => {
    if (token) {
      localStorage.clear("experianData");
      localStorage.clear("TDFToken");
      localStorage.clear("loginConform");
      navigate("/");
    } else {
      navigate("/login");
    }
  };
  const location = useLocation();
  const pathname = location.pathname;
  const handlegoContact = () => {
    navigate("/contact-us");
  };
  const handlegoAbout = () => {
    navigate("/about-us");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const handlegoHome = () => {
    navigate("/");
  };
  const handleGoBlog = () => {
    // navigate("/blog/")
    window.location.href = "/blog/";
  };
  const gotoDashboard = () => {
    navigate("/dashboard/reports");
  };
  const gofaq = () => {
    navigate("/faq");
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: { xs: "0 16px", sm: "0 16px", md: "0 35px", lg: "0 35px" },
          marginTop: "1.5rem",
          background:
            pathname.includes("/login/user") ||
              pathname.includes("/login/experianfullform") ||
              pathname.includes("/login/otpverification")
              ? "#044A67"
              : "",
        }}
      >
        <Box tyle={{ padding: "20px" }}>
          {/* {pathname.includes("AboutUs") && ( */}
          <img
            src={
              pathname === "/" ||
                pathname.includes("/login/user") ||
                pathname.includes("/login/experianfullform") ||
                pathname.includes("/login/otpverification")


                ? whiteLogo
                : logo
            }
            alt="logo"
            style={{
              width: "145px",
              height: "auto",
              cursor: "pointer",
              // marginLeft:"20px",
            }}
            onClick={handlegoHome}
          />
          {/* )} */}
        </Box>
        {
        pathname === "/login/otpverification" ||
          pathname === "/login/user" ||
          pathname === "/login" ? (
          ""
        ) : (
          <>
            {/* Show buttons on larger screens */}
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <Box sx={{ marginRight: "70px" }}>
                {token && loginConform === "loginConform" ? (
                  <>
                   {/* {pathname.includes("/creditdetails") ? (<>
            

                   </>):(<> */}
                    <Button
                      sx={{
                        fontSize: "16px",
                        color: "black",
                        color:
                          pathname === "/" ||
                            pathname.includes("/login/user") ||
                            pathname.includes("/login/experianfullform") ||
                            pathname.includes("/login/otpverification")
                            ? "hsla(0,0%,100%,.5)"
                            : "black",

                        textTransform: "capitalize",
                        "&:hover": {
                          color:
                            pathname === "/" ||
                              pathname.includes("/login/user") ||
                              pathname.includes("/login/experianfullform") ||
                              pathname.includes("/login/otpverification")
                              ? "#fff"
                              : "", // Keeps the background white on hover
                        },
                        mx: 1,
                        fontFamily: "CustomFontMedium",
                      }}
                      onClick={gotoDashboard}
                    >
                      Dashboard
                    </Button>

                   {/* </>)} */}
                
                  </>
                ) : (
                  <></>
                )}
                {pathname.includes("/about-us") ||
                  pathname.includes("/login/experianfullform")
                  ? (<></>) : (<>
                    <Button
                      sx={{
                        fontSize: "16px",

                        color:
                          pathname === "/" ||
                            pathname.includes("/login/user") ||
                            pathname.includes("/login/experianfullform") ||
                            pathname.includes("/login/otpverification")

                            ? "hsla(0,0%,100%,.5)"
                            : "black",
                        textTransform: "capitalize",
                        mx: 1,
                        fontFamily: "CustomFontMedium",
                        "&:hover": {
                          color:
                            pathname === "/" ||
                              pathname.includes("/login/user") ||
                              pathname.includes("/login/experianfullform") ||
                              pathname.includes("/login/otpverification")
                              ? "#ffff"
                              : "", // Keeps the background white on hover
                        },
                      }}
                      onClick={handlegoAbout}
                    >
                      About Us
                    </Button>
                  </>)}
                {pathname.includes("/contact-us") ||
                  pathname.includes("/login/experianfullform") ? (<></>) : (<>
                    <Button
                      sx={{
                        fontSize: "16px",

                        color:
                          pathname === "/" ||
                            pathname.includes("/login/user") ||
                            pathname.includes("/login/experianfullform") ||
                            pathname.includes("/login/otpverification")
                            ? "hsla(0,0%,100%,.5)"
                            : "black",
                        "&:hover": {
                          color:
                            pathname === "/" ||
                              pathname.includes("/login/user") ||
                              pathname.includes("/login/experianfullform") ||
                              pathname.includes("/login/otpverification")
                              ? "#ffff"
                              : "", // Keeps the background white on hover
                        },

                        textTransform: "capitalize",
                        mx: 1,
                        fontFamily: "CustomFontMedium",
                      }}
                      onClick={handlegoContact}
                    >
                      Contact Us
                    </Button>
                  </>)}

                {pathname.includes("/faq") ||
                  pathname.includes("/login/experianfullform") ? (<></>) : (<>
                    <Button
                      sx={{
                        fontSize: "16px",

                        color:
                          pathname === "/" ||
                            pathname.includes("/login/user") ||
                            pathname.includes("/login/experianfullform") ||
                            pathname.includes("/login/otpverification")
                            ? "hsla(0,0%,100%,.5)"
                            : "black",
                        textTransform: "capitalize",
                        "&:hover": {
                          color:
                            pathname === "/" ||
                              pathname.includes("/login/user") ||
                              pathname.includes("/login/experianfullform") ||
                              pathname.includes("/login/otpverification")
                              ? "#ffff"
                              : "", // Keeps the background white on hover
                        },

                        mx: 1,
                        fontFamily: "CustomFontMedium",
                      }}
                      onClick={gofaq}
                    >
                      FAQ's
                    </Button>
                  </>)}
                {
                  pathname.includes("/login/experianfullform") ? (<></>) : (
                    <Button
                      sx={{
                        fontSize: "16px",

                        color:
                          pathname === "/" ||
                            pathname.includes("/login/user") ||
                            pathname.includes("/login/experianfullform") ||
                            pathname.includes("/login/otpverification")
                            ? "hsla(0,0%,100%,.5)"
                            : "black",

                        textTransform: "capitalize",
                        mx: 1,
                        fontFamily: "CustomFontMedium",
                        "&:hover": {
                          color:
                            pathname === "/" ||
                              pathname.includes("/login/user") ||
                              pathname.includes("/login/experianfullform") ||
                              pathname.includes("/login/otpverification")
                              ? "#ffff"
                              : "", // Keeps the background white on hover
                        },
                      }}
                      onClick={handleGoBlog}
                    >
                      Blogs
                    </Button>
                  )}


              </Box>
              {pathname.includes("login")  ? (
                <></>
              ) : (
                <>
                  {/* <Button
                    sx={{
                      fontSize: "16px",
                      borderRadius: "26px",
                      color:
                        pathname.includes("/login/user") ||
                          pathname.includes("/login/experianfullform") ||
                          pathname.includes("/login/otpverification") ||
                          pathname === "/contact-us" ||
                          pathname === "/about-us" ||
                          pathname === "/faq" ||
                          pathname === "/terms-&-conditions"
                          ? "#fff" // Set color to white for "Contact Us"
                          : pathname.includes("/login")
                            ? "hsla(0,0%,100%,.5)"
                            : "black",
                      background:
                        pathname === "/contact-us" ||
                          pathname === "/about-us" ||
                          pathname === "/faq" ||
                          pathname === "/terms-&-conditions"
                          ? "black"
                          : pathname === "/"
                            ? "#fff"
                            : "black",
                      height: pathname === "/" ? "28px" : "",
                      "&:hover": {
                        background:
                          pathname === "/contact-us" ||
                            pathname === "/about-us" ||
                            pathname === "/faq" ||
                            pathname === "/terms-&-conditions"
                            ? "black"
                            : "#fff", // Keep black background on hover for "Contact Us"
                        color:
                          pathname === "/contact-us" ||
                            pathname === "/about-us" ||
                            pathname === "/faq" ||
                            pathname === "/terms-&-conditions"
                            ? "#fff"
                            : "", // Ensure color stays white on hover for "Contact Us"
                      },
                      textTransform: "capitalize",
                      mx: 1,
                      fontFamily: "CustomFontMedium",
                      padding: "0 20px",
                    }}
                    onClick={handleLogout}
                  >
                    {token && loginConform === "loginConform"
                      ? "Log out"
                      : "Login"}
                  </Button> */}
                  <Button
  sx={{
    fontSize: "16px",
    borderRadius: "26px",
    color: 
      ["/login/user", "/login/experianfullform", "/login/otpverification", "/contact-us", "/about-us", "/faq", "/terms-&-conditions", "/creditdetails"].some(path => pathname.includes(path)) 
        ? "#fff" // Set color to white for specific pages
        : pathname.includes("/login")
          ? "hsla(0,0%,100%,.5)"
          : "black",
    background: 
      ["/contact-us", "/about-us", "/faq", "/terms-&-conditions", ].includes(pathname)
        ? "black" // Black background for specific pages
        : pathname === "/"
          ? "#fff"
          : "black",
    height: pathname === "/" ? "28px" : "",
    "&:hover": {
      background: 
        ["/contact-us", "/about-us", "/faq", "/terms-&-conditions","/creditdetails" ].includes(pathname)
          ? "black" // Keep black background on hover
          : "#fff",
      color: 
        ["/contact-us", "/about-us", "/faq", "/terms-&-conditions", "/creditdetails" ].includes(pathname)
          ? "#fff" // Ensure color stays white on hover
          : ""
    },
    textTransform: "capitalize",
    mx: 1,
    fontFamily: "CustomFontMedium",
    padding: "0 20px",
  }}
  onClick={handleLogout}
>
  {token && loginConform === "loginConform" ? "Log out" : "Login"}
</Button>

                </>
              )}
            </Box>

            {/* Show 3-dot icon on mobile screens */}
            {     pathname.includes("/login/experianfullform") ? (<></>):(<>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                onClick={handleClick}
                style={{
                  color:
                    pathname === "/" ||
                      pathname.includes("/login/user") ||
                      pathname.includes("/login/experianfullform") ||
                      pathname.includes("/login/otpverification")
                     
                      ? "#fff"
                      : "",
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
            </>)}
         
          </>
        )}
      </Box>

      {/* Popover for mobile view */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container direction="column" spacing={1}>
            {token && loginConform === "loginConform" ? (
              <>
                <Grid item>
                  <Button
                    sx={{
                      color: "black",
                      textTransform: "capitalize",
                      fontFamily: "CustomFontMedium",
                    }}
                    onClick={gotoDashboard}
                  >
                    <DashboardIcon style={{ cursor: "pointer", position: "relative", right: "10px", fontSize: "16px" }} />
                    Dashboard
                  </Button>
                </Grid>

           
            
              </>
            ) : (
              <></>
            )}

            <Grid item>
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "CustomFontMedium",
                }}
                onClick={handlegoAbout}
              >
                <InfoIcon style={{ cursor: "pointer", position: "relative", right: "10px", fontSize: "16px" }} />
                About Us
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "CustomFontMedium",
                }}
                onClick={handlegoContact}
              >
                <Face6Icon style={{ cursor: "pointer", position: "relative", right: "10px", fontSize: "16px" }} />  Contact Us
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "CustomFontMedium",
                }}
                onClick={gofaq}
              >
                <QuizIcon style={{ cursor: "pointer", position: "relative", right: "10px", fontSize: "16px" }} />
                FAQ's
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "CustomFontMedium",
                }}
                onClick={handleGoBlog}
              >
                <ArticleIcon style={{ cursor: "pointer", position: "relative", right: "10px", fontSize: "16px" }} />
                Blogs
              </Button>
            </Grid>
            {pathname.includes("/login/user") ||
              pathname.includes("/login/experianfullform") ||
              pathname.includes("/login") ||
              pathname.includes("/login/otpverification") ? (
              ""
            ) : (
              <Grid item>
                <Button
                  sx={{
                    color: "black",
                    textTransform: "capitalize",
                    // mx: 1,
                    fontFamily: "CustomFontMedium",
                  }}
                  onClick={handleLogout}
                >
                  <LogoutIcon style={{ cursor: "pointer", position: "relative", right: "10px", fontSize: "16px" }} />  {token && loginConform === "loginConform" ? "Log out" : "Login"}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderComponent;

