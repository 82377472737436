import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { Box} from "@mui/material";


function Loading() {
    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 1000, // Higher z-index to make sure it's on top
                }}
            >
                <CircularProgress />
            </Box>
        </>
    )
}
export default Loading;