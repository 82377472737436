import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axiosInstance from "../service/axiosInstance";

export default function SettlementListCompo({ listingData }) {
  const handleDownload = async (id) => {
    const token = localStorage.getItem("TDFToken");
  
    if (token) {
      const axiosConfig = {
        responseType: "blob", // Ensure we are dealing with a binary response
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      try {
        const response = await axiosInstance.get(`/tdf/api/downloadSettlementLetter/${id}`, axiosConfig);
  
        // Check for successful response
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: "application/pdf" });
  
          // Create a download URL for the blob
          const url = window.URL.createObjectURL(blob);
  
          // Create a temporary link to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "settlement.pdf");
  
          // Append the link to the DOM
          document.body.appendChild(link);
  
          // Trigger the download
          link.click();
  
          // Clean up: remove the link and revoke the object URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          console.error("Failed to download file, status code:", response.status);
        }
      } catch (err) {
        console.error("Error downloading file:", err);
      }
    }
  };
  

  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Bank Name</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Created Date</TableCell>
            <TableCell align="right">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listingData &&
            listingData.map((data, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#f5f5f5" }, // Change the color as needed
                }}
              >
                <TableCell component="th" scope="row">
                  {data?.bankName}
                </TableCell>
                <TableCell align="right">{data?.amount}</TableCell>
                <TableCell align="right">{data?.status}</TableCell>
                <TableCell align="right">
                  {/* {data?.createdDate} */}
                  {data?.createdDate ? new Date(data.createdDate).toLocaleDateString() : ''}
                  </TableCell>
                <TableCell align="right">
                 {data?.docUploaded ? (<>
                  <FileDownloadIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDownload(data?.accountDetailsId)}
                  />
                 </>):(<></>)}
              
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
