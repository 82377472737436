import React, { useState } from 'react'
import AccountsCard from '../DashboardComponents/AccountsCard'
import LoanCard from '../DashboardComponents/LoanCard'
import AccountChart from '../DashboardComponents/AccountChart'
import DonutChart from '../DashboardComponents/DonutChart'
import SAccountsCard from '../DashboardComponents/SAccountsCard'
import AccountsCreditCard from '../DashboardComponents/AccountsCreditCard'

import { 
    useMediaQuery,
  } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function CreditMix({ creditMixdata }) {
    const isMobile = useMediaQuery("(max-width:600px)");
    const [expandedPanel, setExpandedPanel] = useState(null);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
      setExpandedPanel(isExpanded ? panel : null);
    };

    const cardData = [
        {
            id: 1, title: 'Total Amount', amount: creditMixdata?.totalAmount,
            addedAmt: creditMixdata?.amountAddedLastThreeMonths,
            preAmt: creditMixdata?.totalAmount - creditMixdata?.amountAddedLastThreeMonths
        },

        {
            id: 2, title: 'Total Outstanding Amount', amount: creditMixdata?.totalOutstandingAmount,
            addedAmt: creditMixdata?.outstandingAmountAddedLastThreeMonths,
            preAmt: creditMixdata?.totalOutstandingAmount - creditMixdata?.outstandingAmountAddedLastThreeMonths
        },

        {
            id: 3, title: 'Total Collateral Amount', amount: creditMixdata?.totalCollateralAmount,
            addedAmt: creditMixdata?.collateralAmountAddedInLastThreeMonths,
            preAmt: creditMixdata?.totalCollateralAmount - creditMixdata?.collateralAmountAddedInLastThreeMonths
        },
    ];
    return (
        <>
        
            <div style={{background:"#FFFF",borderRadius:"24px"}}>


                {/* card section start */}
                <div className='topdiv'>
                    {/* <div className='childdiv'>
                        <DonutChart  creditMixdata ={creditMixdata}/>
                    </div> */}
                    {/* <div className='childdiv2'> */}
                    <DonutChart  creditMixdata ={creditMixdata}/>

                        {cardData.map(card => (
                            <div className='card' key={card.id}>
                                <div className='cardchild'>
                                    <div className='text-of-card'>{card.title}</div>
                                    <div className='imgsection'>
                                        <div>
                                            <AccountChart data1={card} />
                                        </div>
                                        {card?.preAmt || card?.addedAmt ? (<>
                                            <div className='textDiv'>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#7CB342', marginRight: '5px' }} />
                                        <div className='text-div-2'>Latest Amount Added    
                                            
                                        </div>

                                        </div>
                                          
                                            <div className='text-div-amt'>₹{card.addedAmt?.toLocaleString('en-IN')}</div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: 'black', marginRight: '5px' }} />
                                            <div className='text-div-2'>Previous Amount </div>
                                            </div>                                          
                                            <div className='text-div-amt'>₹{card.preAmt?.toLocaleString('en-IN')}</div>
                                            {card?.preAmt || card?.addedAmt ? (<>
                                            </>):(<>
                                                <div style={{display:"flex", alignItems:"center"}}>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: '#907AD6', marginRight: '5px' }} />
                                            <span style={{ marginLeft: '5px', fontFamily:"Nunito Sans",fontWeight:"600", fontSize:"11px" }}>No Data available</span>
                                            </div>  
                                            </>)}
                                                                             
       
       


                                        </div>

                                        </>):(<>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: '#907AD6', marginRight: '5px' }} />
                                            <span style={{ marginLeft: '5px', fontFamily:"Nunito Sans",fontWeight:"600", fontSize:"11px" }}>No Data available</span>
                                            </div>   

                                        </>)}
                                     

                                    </div>

                                    <div className='totalamount'>
                                        <div className='cardammount'>₹{card.amount?.toLocaleString('en-IN')}</div>
                                        <div className='tip'>Total amount you have till date</div>
                                    </div>
                                </div>
                            </div>
                        ))}



                    {/* </div> */}
                </div>
            
                <div className='loanType'>
                    {isMobile ? (<>
                        <Accordion
                          expanded={expandedPanel === 'secured'}
                          onChange={handleAccordionChange('secured')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className='section-heading-1'
        >
          Secured
        </AccordionSummary>
        <AccordionDetails>
        <div className='Secured'>
                        {/* <p className='section-heading-1' >Secured</p> */}
                        <AccountsCard creditMixdata={creditMixdata} />
                        <div style={{ marginTop: "20px", minHeight: "50vh", overflow: "auto", maxHeight: "50vh", borderRadius: "10px", padding: "10px", borderTop: "1px solid #D2D1D1", scrollbarWidth: "thin",background:"hsla(215, 15%, 97%, 0.5)" }}>
                            {creditMixdata?.secureLoanAccountListDTOS?.sort((a, b) => (a.status === "ACTIVE"  || a.status === "SF/WD/WO/SETTLED" ? -1 : 1)).map((loan) => (
                                <LoanCard loan={loan} />
                            ))}
                        </div>   


                    </div>
     
        </AccordionDetails>
                       </Accordion> 
                    
                    </>):(<>
                        <div className='Secured'>
                        <p className='section-heading-1' >Secured</p>
                        <AccountsCard creditMixdata={creditMixdata} />
                        <div style={{ marginTop: "20px", minHeight: "50vh", overflow: "auto", maxHeight: "50vh", borderRadius: "10px", padding: "10px", borderTop: "1px solid #D2D1D1", scrollbarWidth: "thin",background:"hsla(215, 15%, 97%, 0.5)" }}>
                            {creditMixdata?.secureLoanAccountListDTOS?.sort((a, b) => (a.status === "ACTIVE"  || a.status === "SF/WD/WO/SETTLED" ? -1 : 1)).map((loan) =>  (                               
                                <LoanCard loan={loan} />
                            ))}
                        </div>   


                    </div>
                    </>)}      

                    {isMobile ? (<>
                        <Accordion     expanded={expandedPanel === 'unsecured'}
            onChange={handleAccordionChange('unsecured')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className='section-heading-1'
        >
          Unsecured
        </AccordionSummary>
        <AccordionDetails>
      
        <div className='Unsecured'>
                        {/* <p className='section-heading-1'>Unsecured</p> */}
                        <SAccountsCard
                        creditMixdata={creditMixdata} 
                        />
                        <div style={{  marginTop: "20px", minHeight: "50vh", overflow: "auto", maxHeight: "50vh", borderRadius: "10px", padding: "10px", borderTop: "1px solid #D2D1D1", scrollbarWidth: "thin",background:"hsla(215, 15%, 97%, 0.5)" }}>


                            {creditMixdata?.unsecureLoanAccountListDTOS?.sort((a, b) => (a.status === "ACTIVE"  || a.status === "SF/WD/WO/SETTLED" ? -1 : 1)).map((loan, index) => (
                                <LoanCard loan={loan}  key={index + "creditmix-data"}/>
                            ))}
                            
                        </div>

                    </div>
        </AccordionDetails>
               </Accordion> 

                    </>):(<>
                        <div className='Unsecured'>
                        <p className='section-heading-1'>Unsecured</p>
                        <SAccountsCard
                        creditMixdata={creditMixdata} 
                        />
                        <div style={{  marginTop: "20px", minHeight: "50vh", overflow: "auto", maxHeight: "50vh", borderRadius: "10px", padding: "10px", borderTop: "1px solid #D2D1D1", scrollbarWidth: "thin",background:"hsla(215, 15%, 97%, 0.5)" }}>

{/* 
                            {creditMixdata?.unsecureLoanAccountListDTOS?.map((loan, index) => (
                                <LoanCard loan={loan}  key={index + "creditmix-data"}/>
                            ))} */}                            
    {creditMixdata?.unsecureLoanAccountListDTOS
    ?.sort((a, b) => (a.status === "ACTIVE" || a.status === "SF/WD/WO/SETTLED" ? -1 : 1)) // Sort "ACTIVE" status first
    .map((loan, index) => (
        <LoanCard loan={loan} key={index + "creditmix-data"} />
    ))}


                        </div>

                        </div>
                    </>)} 

                    {isMobile ? (<>
                        <Accordion 
                         expanded={expandedPanel === 'creditCards'}
                         onChange={handleAccordionChange('creditCards')}
                        >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className='section-heading-1'
        >
          Credit Cards
        </AccordionSummary>
        <AccordionDetails style={{padding:"0"}}>
      
        <div className='CreditCards'>
                        {/* <p className='section-heading-1'>Credit Cards</p> */}
                        <AccountsCreditCard
                         creditMixdata={creditMixdata} />
                        <div style={{ marginTop: "20px", minHeight: "50vh", overflow: "auto", maxHeight: "50vh", borderRadius: "10px", padding: "10px", borderTop: "1px solid #D2D1D1", scrollbarWidth: "thin",background:"hsla(215, 15%, 97%, 0.5)"}}>

                        {creditMixdata?.creditAccountListDTOS?.sort((a, b) => (a.status === "ACTIVE" || a.status === "SF/WD/WO/SETTLED" ? -1 : 1)).map((loan) => (
                                <LoanCard loan={loan} />
                            ))}
                            
                         

                        </div>

                    </div>
        </AccordionDetails>
               </Accordion>
                    </>):(<>
                        <div className='CreditCards'>
                        <p className='section-heading-1'>Credit Cards</p>
                        <AccountsCreditCard
                         creditMixdata={creditMixdata} />
                        <div style={{ marginTop: "20px", minHeight: "50vh", overflow: "auto", maxHeight: "50vh", borderRadius: "10px", padding: "10px", borderTop: "1px solid #D2D1D1", scrollbarWidth: "thin",background:"hsla(215, 15%, 97%, 0.5)"}}>

                        {creditMixdata?.creditAccountListDTOS?.sort((a, b) => (a.status === "ACTIVE"  || a.status === "SF/WD/WO/SETTLED" ? -1 : 1)).map((loan) => (
                                <LoanCard loan={loan} />
                            ))}
                         

                        </div>

                    </div>
                    </>)}           
                 
              
                 

                </div>
             
            </div>
        </>
    )
}

export default CreditMix