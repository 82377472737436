import React from "react";
import img2 from "../assets/imgs/identify bad loans due factory.png";
import { Box, Grid, Typography } from "@mui/material";

const Page2 = () => {
  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={0}
          sm={5}
          lg={5}
          sx={{ display: { xs: "none", sm: "block", lg: "block" } }}
        >
          <Box
            sx={{
              background: "linear-gradient(to right, #044A67, #0a6b90)",
              // padding: "10px",
              display: "flex",
              justifyContent: "center",
              height: "100vh",
              alignItems: "center",
            }}
          >
            <img
              src={img2}
              alt="mobile"
              height="70%"
              style={{ marginTop: "30px" }}
            />
          </Box>
        </Grid>
        {/* How Its Works Section Start */}
        <Box>
          <svg
            className="editorial"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 
                    58-18 88-18s
                    58 18 88 18 
                    58-18 88-18 
                    58 18 88 18
                    v44h-352z"
              />
            </defs>
            <g className="parallax1">
              <use xlinkHref="#gentle-wave" x="50" y="3" fill="#f461c1" />
            </g>
            <g className="parallax2">
              <use xlinkHref="#gentle-wave" x="50" y="0" fill="#4579e2" />
            </g>
            <g className="parallax3">
              <use xlinkHref="#gentle-wave" x="50" y="9" fill="#3461c1" />
            </g>
            <g className="parallax4">
              <use xlinkHref="#gentle-wave" x="50" y="6" fill="#fff" />
            </g>
          </svg>
        </Box>
        <Grid
          item
          xs={12}
          sm={7}
          lg={7}
          sx={{
            display: { xs: "block", sm: "flex", lg: "flex" },
            background: "#bde4f7",justifyContent:"center",alignItems:"center"
          }}
        >
          <Box
            sx={{
              // marginTop: "125px",
              textAlign: "justify",
              padding: "0 140px 30px 45px",
              position: "relative",
            }}
          >
            <Box
                  sx={{
                    position: "absolute",
                    top: "119px",
                    right: "249px",
                    width: "100px",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    opacity: "0.5",
                    fontSize: "100px",
                    color: "#b2abab61",
                    fontWeight: "600",
                    textAlign: "center",
                    fontSize:"800px"
                  }}
            >
              2
            </Box>
            <Typography
              style={{ fontSize: "26px", fontFamily: "CustomFontMedium" }}
            >
              How It Works?
            </Typography>
            <Typography
         style={{
          fontSize: "20px",
          // marginTop: "16px",
          color:"#8c8080",
          fontFamily: "CustomFontMedium",
          lineHeight:"normal",
        }}
            >
              At Due Factory, we help you bring up your sub-optimal credit score
              which is a result of your financial history. Missed EMIs on a
              previous loan? Forgot to pay your CC bill months ago? Having
              difficulty in getting a new loan?
            </Typography>
            <Box style={{ marginTop: "60px" }}>
              <Typography
                style={{
                  fontSize: "40px",
                  color: "#27498b",
                  fontWeight: "700",
                  fontFamily: "CustomFontMedium",
                }}
              >
                Identify your bad loans
              </Typography>
              <Typography
             style={{
              fontSize: "20px",
              // marginTop: "16px",
              color:"#8c8080",
              fontFamily: "CustomFontMedium",
              lineHeight:"normal",
            }}
              >
                Based on your previous payment history. Identify loans and
                Credit cards which you must start addressing in order to elevate
                your credit health.
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* How Its Works Section end */}
      </Grid>
    </Box>
  );
};

export default Page2;
