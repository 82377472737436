import React from 'react'

function AccountsCreditCard({creditMixdata }) {
    return (
        <>
            <div className='SecuredCard'>

                <div className='totalamtCardCredit'>
                    <div className='textcardDiv' >
                        <div className='card-details-div-1'>
                            <div> Total Credit Limit : </div>
                            <div>Total Current Outstanding : </div>
                            <div>Billed Due: </div>
                        </div>
                        <div className='card-details-div-2'>
                            <div>₹{creditMixdata?.totalCreditAmount?.toLocaleString('en-IN') }</div>
                            <div>₹{creditMixdata?.outstandingCreditAmount?.toLocaleString('en-IN')}</div>
                            <div>₹{creditMixdata?.creditAmountPastDue?.toLocaleString('en-IN')}</div>

                        </div>
                    </div>
                </div>

                <div className='Accinfo'>
                    <div className='acctiveAccounts'>
                        <div className='text-account-num'>{creditMixdata?.creditActiveAccounts}</div>
                        <div className='text-account-text' >Active Accounts</div>
                    </div>

                    <div className='closedAccounts'>
                        <div className='text-account-num'  >{creditMixdata?.creditClosedAccounts}</div>
                        <div className='text-account-text' >Closed Accounts</div>
                    </div>



                </div>

            </div>
        </>
    )
}

export default AccountsCreditCard;