import React, { useEffect, useState } from "react";
import "./PremiumDashboard.css";
import PremiumReport from "../CentralMenu/PremiumReport";
import CreditMix from "./CreditMix";
import axiosInstance from "../service/axiosInstance";
import Loading from "../Components/Loading";
import HeaderComponent from "../Components/HeaderCoponent";
import FotterComponent from "../Components/FotterComponent";

function PremiumDashboard() {
  const [activeTab, setActiveTab] = useState("All");
  const [insightData, setInsightData] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSwitch = (tab) => {
    setActiveTab(tab);
  };
  const insightReportApi = () => {
    const token = localStorage.getItem("TDFToken");
    setLoading(true);

    axiosInstance
      .post(
        "/tdf/api/userBureauInsight/getInsight",
        // "/tdf/api/userBureauInsight/getInsight/9812173042",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setInsightData(res?.data?.response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };




  const getLeftPosition = () => {
    switch (activeTab) {
      case "All":
        return "1%";
      case "Good":
        return "33.33%";
      case "Bad":
        return "65.66%";
      default:
        return "0%";
    }
  };
  const renderContent = () => {
    switch (activeTab) {
      case "All":
        return <CreditMix creditMixdata={insightData?.insightCreditMix?.ALL} />
      case "Good":
        return insightData?.insightCreditMix?.GOOD?.totalAccounts >= 1 ? (
          <>
            <CreditMix creditMixdata={insightData?.insightCreditMix?.GOOD} />
          </>
        ) : (
          <>
            <div style={{ height: "50vh" }}>
              <div
                style={{
                  height: "210px",
                  background: "#ECF0F6",
                  borderRadius: "24px",
                  padding: "10px 20px",
                  margin:"10px"
                }}
              >
                <p className="bad-account-msg">
                  😞 “It looks like there are some challenges with your current
                  loans. But remember, it’s never too late to make a positive
                  change. We’re here to support you on your path to financial
                  stability.”
                </p>
              </div>
            </div>
          </>
        )
      case "Bad":
        return insightData?.insightCreditMix?.BAD?.totalAccounts >= 1 ? (
          <>
            <CreditMix creditMixdata={insightData?.insightCreditMix?.BAD} />
          </>
        ) : (
          <>
            <div style={{ height: "50vh" }}>
              <div
                style={{
                  height: "210px",
                  background: "#ECF0F6",
                  borderRadius: "24px",
                  padding: "10px 20px",
                  margin:"10px"
                }}
              >
                <p className="bad-account-msg">
                  🌟 “Impressive! Zero bad loans on your record—your financial
                  game is on point! Keep it going strong!”
                </p>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };
  useEffect(() => {
    insightReportApi()
  }, [])
  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div style={{ paddingBottom: "1.5rem" }}>
            <HeaderComponent />
          </div>


          <PremiumReport data={insightData} />
          <div style={{background:'#ECF0F6'}}>

          <div className="top-div-pre">            
                  
            <div className="parentDiv1">
              <div
                style={{
                  padding: "20px",
                  fontSize: "18px",
                  fontFamily: "Nunito Sans', sans-serif",
                  fontWeight: "600",
                }}
              >
                <span>Credit Mix</span>
              </div>
              <div className="switch-container-top">
                <div className="switch-container">
                  <div
                    className="switch-background"
                    style={{ left: getLeftPosition() }}
                  ></div>
                  <div
                    className={`switch-btn ${activeTab === "All" ? "active" : ""
                      }`}
                    onClick={() => handleSwitch("All")}
                  >
                    {` All Accounts (${insightData?.insightCreditMix?.ALL?.totalAccounts})`}

                  </div>
                  <div
                    className={`switch-btn ${activeTab === "Good" ? "active" : ""
                      }`}
                    onClick={() => handleSwitch("Good")}
                  >
                    {`Good Accounts (${insightData?.insightCreditMix?.GOOD?.totalAccounts})`}


                  </div>
                  <div
                    className={`switch-btn ${activeTab === "Bad" ? "active" : ""
                      }`}
                    onClick={() => handleSwitch("Bad")}
                  >
                    {`Bad Accounts (${insightData?.insightCreditMix?.BAD?.totalAccounts})`}
                  </div>
                </div>
              </div>
              <div>{renderContent()}</div>

            </div>
       

          </div>

          </div>
          <FotterComponent />
        </>
      )}
    </>
  )
}

export default PremiumDashboard;

