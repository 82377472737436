import {React,useEffect,useState} from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

import { Box, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CustomizedInputBase() {
  const [mobile, setMobile] = useState("");
  const navigate = useNavigate();
  const gotoLogin = () => {
    if (mobile.length === 10) {
      navigate("/login", { state: { mobile: mobile } });
    } else {
      toast.error("Please enter a valid 10-digit phone number.");
    }
  };
  const handleChange = (e) => {
    setMobile(e.target.value);
  };
  useEffect(() => {
    const phoneInput = document.getElementById("phone-input");
    if (phoneInput) {
      const style = document.createElement("style");
      style.innerHTML = `
          #phone-input::-webkit-inner-spin-button,
          #phone-input::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
            display: none; /* Chrome, Safari */
          }
          #phone-input[type=number] {
            -moz-appearance: textfield; /* Firefox */
          }
        `;
      phoneInput.appendChild(style);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: { xs: "10px", sm: "none", lg: "none" },
      }}
    >
      <Paper
        component="form"
        sx={{  display: "flex", alignItems: "center", width: 445 ,background:"black",borderRadius:"10px", height:{xs:"50",lg:"73px"}}}
      >
        <InputBase
          sx={{ ml: 3, flex: 1,color:"#fff",fontFamily: "CustomFontMedium",  textAlign: "center",
            "& input::placeholder": {
              textAlign: "center", // Center the placeholder text
            },}}
          placeholder="Enter Mobile Number"
          inputProps={{ "aria-label": "Enter Mobile Number" }}
          value={mobile}
          onChange={handleChange}
          type="number"          
          id="phone-input"
        />

        <IconButton
          color="primary"
          sx={{
            p: "10px",
            background: "#044A67 0% 0% no-repeat padding-box",
            color: "#fff",
            borderRadius: "5px",
            margin: "5px",
            "&:hover": {
              background: "#044A67 0% 0% no-repeat padding-box", // Change this to the color you want on hover
              color: "#fff", // Optional: Change text color on hover
            },
            height:"50px",
            width:{xs:"135px", lg:"197px"},
            height:{xs:"43px",lg:"60px"}
         
          }}
          aria-label="directions"
          onClick={gotoLogin}
     
        >
          <Typography
            sx={{
              display: "inline-block",
              fontFamily: "CustomFontMedium",
              fontSize:{xs:"10px", lg:"18px"} ,
              fontWeight: "bold",
            }}
          >
            Get Started
          </Typography>{" "}
          <ArrowForwardIcon
            sx={{ background: "#19A6B7", fontSize: "25px", marginLeft: "10px",borderRadius:"5px", padding:{xs:"none", lg:"10px"}}}
          />
        </IconButton>
      </Paper>
    </Box>
  );
}
