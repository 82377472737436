import React, { useState } from 'react';
import './MyCarousel.css';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import { IconButton } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
const slides = [
    { id: 1, component: <Page1 /> },
    { id: 2, component: <Page2 /> },
    { id: 3, component: <Page3 /> },
    { id: 4, component: <Page4 /> },
    { id: 5, component: <Page5 /> },
];

const MyCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    };

    return (
        <div className="carousel">
            <div className="carousel-slide">
                {slides[currentIndex].component}
            </div>
            <div className="carousel-controls">
                <IconButton className="prev" onClick={prevSlide}>
                    <NavigateBeforeIcon style={{ fontSize: 48, color: 'white' }} />
                </IconButton>
                <IconButton className="next" onClick={nextSlide}>
                    <NavigateNextIcon style={{ fontSize: 48, color: 'white' }} />
                </IconButton>
            </div>
        </div>
    );
};

export default MyCarousel;
