import { ToastContainer } from 'react-toastify';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './routes';
import ClearLocalStorageOnDateChange from './utils/ClearLocalStorageOnDateChange';
function App() {
  return (
    <div>      
      <AppRoutes/>
      {/* this autometcaly clear token after date change */}
      <ClearLocalStorageOnDateChange/>
      <ToastContainer />
    </div>
  );
}

export default App;
