import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the components for Chart.js
Chart.register(ArcElement, Tooltip, Legend);

const DonutChart = ({creditMixdata}) => {
  console.log("creditMixdatain chart:",creditMixdata)
  
 const totalAccounts = creditMixdata?.totalAccounts ?? 1; 
const securedAccounts = creditMixdata?.securedAccounts ?? 0;
const SecuredPer = (securedAccounts / totalAccounts) * 100;


const unsecuredAccounts = creditMixdata?.unsecuredAccounts ?? 0;
const unsecuredper =  (unsecuredAccounts / totalAccounts) * 100;

const creditCardAccounts = creditMixdata?.creditCardAccounts ?? 0;
const creditper =  (creditCardAccounts / totalAccounts) * 100;
 
  console.log("SecuredPer:",SecuredPer)

  const data = {
    labels: [
      'Secured',
      'Unsecured',
      'Credit Card'
    ],
    datasets: [{
      label: "Accounts",
      data: [(SecuredPer || 0).toFixed(2), unsecuredper.toFixed(2), creditper.toFixed(2)],
      backgroundColor: [
        '#907AD6',
        '#DABFFF',
        '#2C2A4A',
      ],
      hoverOffset: 4
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows you to set a fixed width and height
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            const percentage = value + '%'; // Convert to percentage
            const label = tooltipItem.label; // Get the corresponding label
            return `${label}: ${percentage}`; // Format the tooltip text
          }
        }
      },
      legend: {
        display: false // Hide the legend
      }
    }
  };

  return (
    <div className='donut-chart-div-1'>
      <div>
      <div className='chartTopDiv-1'> 
        <Doughnut data={data} options={options} />
      </div>
      </div>
    
      {/* Text Labels */}
      <div style={{fontFamily:'CustomFontMedium', fontSize:"12px", fontWeight:"600", padding:"0 10px"  }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#907AD6', marginRight: '5px' }} />
          <span style={{ marginLeft: '5px', fontFamily:"Nunito Sans",fontWeight:"600", fontSize:"11px" }}>Secured ({(SecuredPer || 0).toFixed(2)}%)</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#DABFFF', marginRight: '5px' }} />
          <span style={{ marginLeft: '5px', fontFamily:"Nunito Sans",fontWeight:"600", fontSize:"11px" }}>Unsecured ({(unsecuredper || 0).toFixed(2)}%)</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#2C2A4A', marginRight: '5px' }} />
          <span style={{ marginLeft: '5px', fontFamily:"Nunito Sans",fontWeight:"600", fontSize:"11px" }}>Credit Card ({(creditper || 0).toFixed(2)}%)</span>
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
