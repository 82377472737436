import React from 'react'

function AccountsCard({creditMixdata }) {
    return (
        <>
            <div className='SecuredCard'>

                <div className='totalamtCard'>
                    <div className='textcardDiv' >
                        <div className='card-details-div-1'>
                            <div> Total Amount : </div>
                            <div>Total Outstanding : </div>
                            <div>Total Amount Pass Due : </div>
                        </div>
                        <div className='card-details-div-2'>
                            <div>₹{creditMixdata?.totalSecuredAmount?.toLocaleString('en-IN') }</div>
                            <div>₹{creditMixdata?.outstandingSecureAmount?.toLocaleString('en-IN')}</div>
                            <div>₹{creditMixdata?.secureAmountPastDue?.toLocaleString('en-IN')}</div>

                        </div>
                    </div>
                </div>

                <div className='Accinfo'>
                    <div className='acctiveAccounts'>
                        <div className='text-account-num'>{creditMixdata?.secureActiveAccounts}</div>
                        <div className='text-account-text' >Active Accounts</div>
                    </div>

                    <div className='closedAccounts'>
                        <div className='text-account-num'  >{creditMixdata?.secureClosedAccounts}</div>
                        <div className='text-account-text' >Closed Accounts</div>
                    </div>



                </div>

            </div>
        </>
    )
}

export default AccountsCard