import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

// Register the components for Chart.js
Chart.register(ArcElement, Tooltip, Legend);

const AccountChart = ({ data1 }) => {
  const isDataZero = data1?.addedAmt === 0 && data1?.preAmt === 0;

  const data = {
    labels: isDataZero ? ['No Data'] : ['Lat. Amt Add', 'Prev Amt'],
    datasets: [
      {
        label: 'Amt',
        data: isDataZero ? [1] : [data1?.addedAmt, data1?.preAmt],
        backgroundColor: isDataZero ? ['#907AD6'] : ['#7CB342', '#000000'], // Blue color when data is zero
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '80%', // Adjust this percentage to make the colored portion thinner
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            // If isDataZero is true, return an empty string to hide the "1" in the tooltip
            return isDataZero ? '' : `${value}`;
          },
        },
      },
      legend: {
        display: false, // Hide the legend
      },
    },
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '80px',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* Doughnut Chart */}
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Doughnut data={data} options={options} />
      </div>

      {/* TrendingUpIcon in the center, with pointer-events set to none */}
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          pointerEvents: 'none', // Prevents the icon from interfering with chart interactions
        }}
      >
        {data1?.addedAmt ? (
          <TrendingUpIcon style={{ fontSize: 24, color: '#7CB342' }} />
        ) : (
          <HorizontalRuleIcon style={{ fontSize: 24, color: 'black' }} />
        )}
      </div>
    </div>
  );
};

export default AccountChart;
