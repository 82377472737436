import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../Pages/Login";
import Sidebar from "../Dashboard/Sidebar";
import CreditReport from "../Dashboard/CreditReport";
import BasicDetails from "../loginFlow/BasicDetails";
import OtpVerification from "../loginFlow/OtpVerification";
import ExperianFullForm from "../loginFlow/ExperianFullForm";
import MySettlement from "../Dashboard/MySettlement";
import ContactusPage from "../Dashboard/ContactusPage";
import FaqComponents from "../Dashboard/FaqComponents";
import Profile from "../Dashboard/Profile";
import AboutUs from "../Pages/AboutUs";
import SettleOffer from "../Dashboard/SettleOffer";
import TermsConditions from "../Pages/TermsConditions";
import HomePage2 from "../Home2/HomePage2";
import RequireAuth, { RequireLS } from "./RequireAuth";
import PageNotFound from "../Pages/PageNotFound";
import HelmetWrapper from "../Dashboard/HelmetWrapper";
import SlashRedirect from "./SlashRedirect";
import PremiumDashboard from "../Dashboard/PremiumDashboard";

function AppRoutes() {
  return (
    <BrowserRouter>
      <SlashRedirect>

        <HelmetWrapper /> {/* This will handle the dynamic SEO tags */}
        <Routes>
          <Route element={<HomePage2 />} path="/" />
          <Route element={<RequireLS >
            <Login />
          </RequireLS>} path="/login" />
          {/* Grouping login-related routes */}
          {/* <Route element={<RequireAuth><RequireLS /></RequireAuth>}> */}

          <Route path="/login/user" element={<RequireAuth><RequireLS ><BasicDetails /></RequireLS></RequireAuth>} />
          <Route path="/login/otpverification" element={<RequireAuth><RequireLS ><OtpVerification /></RequireLS></RequireAuth>} />
          <Route path="/login/experianfullform" element={<RequireAuth><RequireLS ><ExperianFullForm /></RequireLS></RequireAuth>} />
          <Route path="/creditdetails" element={<RequireAuth><PremiumDashboard /></RequireAuth>} />

          {/* </Route> */}



          {/* <Route path="/creditdetails" element={<PremiumDashboard />} /> */}

          <Route element={<AboutUs />} path="/about-us" />

          <Route path="/contact-us" element={<ContactusPage />} />
          <Route path="/terms-&-conditions" element={<TermsConditions />} />
          <Route path="/faq" element={<FaqComponents />} />
          <Route path="/*" element={<PageNotFound />} />

          {/* re writeing code end*/}

          <Route path="/dashboard" element={<RequireAuth><Sidebar /></RequireAuth>}>
            <Route path="reports" element={<CreditReport />} />
            <Route path="settlement" element={<MySettlement />} />
            <Route path="profile" element={<Profile />} />
            <Route path="offer/:id" element={<SettleOffer />} />
            <Route path="terms-and-conditions" element={<TermsConditions />} />
            {/* <Route path="/dashboard/creditdetails" element={<PremiumDashboard />} /> */}

          </Route>
        </Routes>
      </SlashRedirect>
    </BrowserRouter>
  );
}

export default AppRoutes;
