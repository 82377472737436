import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";

const PageNotFound = () => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(10); // Initial countdown set to 5 seconds

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    const navigationTimeout = setTimeout(() => {
      navigate(-1);
    }, 10000);

    return () => {
      clearInterval(countdown);
      clearTimeout(navigationTimeout);
    };
  }, [navigate]);

  return (
    <Box
      sx={{
        padding: 2,
        height: "95vh",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif)`,
          height: "100%",
          width: "100%",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Typography variant="h1" sx={{ fontSize: "4rem", mt: 2 }}>
        404
      </Typography>
      <Typography variant="h6">Page not found</Typography>

      <Button
        sx={{
          "&:hover": {
            background: "black", // Change this to the color you want on hover
            color: "#fff", // Optional: Change text color on hover
          },
          textTransform: "capitalize",
          fontFamily: "CustomFontMedium",
          fontWeight: "600",
          background: "#085E7F",
          color: "white",
          marginTop: "20px",
          paddingLeft: "20px",
          paddingRight: "20px",
          borderRadius:"20px",
        }}
        onClick={() => navigate(-1)}
      >
        Redirecting in <span style={{margin:"5px", color:"red" ,fontFamily: "CustomFontMedium", fontWeight: "600",}}>{timer}</span>seconds...
      </Button>
    </Box>
  );
};

export default PageNotFound;
