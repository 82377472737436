import React, { useEffect, useRef } from "react";
import HeroSection from "./HeroSection";
import WinnerSection from "./WinnerSection";
import MyCarousel from "../Carousel/MyCarousel";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import WhyDueFactory from "../Home/WhyDueFactory";
import SimpleSlider from "../Home/SimpleSlider";
import FotterComponent from "../Components/FotterComponent";
import AdvertiseSection from "./AdvertiseSection";
import HomeFaq from "./HomeFaq";
import CustomizedInputBase from "./CustomizedInputBase";

function HomePage2() {
  const heroSectionRef = useRef(null); // Ref for HeroSection
  const winnerSectionRef = useRef(null); // Ref for WinnerSection
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));





  useEffect(() => {
    if (!isDesktop) return; // Exit if not on desktop

    const handleScroll = (event) => {
      if (heroSectionRef.current) {
        const heroSection = heroSectionRef.current;
        const { scrollTop, scrollHeight } = heroSection;
        const scrollAmount = scrollTop / scrollHeight;

        // If user scrolls even 1px inside HeroSection, scroll to WinnerSection
        if (scrollAmount > 0) {
          winnerSectionRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    };

    const heroSectionElement = heroSectionRef.current;

    if (heroSectionElement) {
      heroSectionElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (heroSectionElement) {
        heroSectionElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isDesktop]);

  return (
    <>
      {/* HeroSection */}
      <Box ref={heroSectionRef} sx={{ height: "100vh", overflowY: "scroll", scrollbarWidth: "none", }}>
        <HeroSection />
      </Box>

      {/* WinnerSection */}
      <Box ref={winnerSectionRef} >
        <WinnerSection />
      </Box>

      <Box
        sx={{
          display: { xs: "none", sm: "none", lg: "block", md: "block" },
        }}
      >
        <MyCarousel />
      </Box>
      <Box>


        <WhyDueFactory />


      </Box>
      <Box
        sx={{
          padding: "20px",
          textAlign: "center",
          padding: {
            sm: "20px",
            xs: "20px",
            lg: "50px",
          },
        }}
      >
        <Typography
          style={{
            fontSize: "36px",
            fontWeight: "600",
            fontFamily: "CustomFontMedium",
            marginTop: "20px"
          }}
        >
         Top Myths about credit score
        </Typography>
      </Box>
      <Box
       sx={{padding:{xs:"0", lg:"0px 83px"}}}
       >
        <SimpleSlider />
      </Box>
      <AdvertiseSection />
      <HomeFaq />
      <Box sx={{ marginTop: "80px", padding: { xs: "0 10px", sm: "0 10px", md: "none", }, }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{
            width: {
              xs: "100%", sm: "100%", md: "50%",
              lg: "21%",
            }
          }}>
            <Typography
              sx={{
                fontFamily: "CustomFontMedium",
                fontSize: "24px",
                textAlign: "center",
                padding: { xs: "0 10px", sm: "0 10px", md: "none" },
                fontWeight: { xs: "600", sm: "500", md: "500" },
              }}
            >
              Connect with us to increase your credit score!
            </Typography>
          </Box>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <CustomizedInputBase />
        </Box>
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <FotterComponent />
      </Box>
    </>
  );
}

export default HomePage2;
