import React from "react";
import img5 from "../assets/imgs/make payments due factory.png";
import { Box, Grid, Typography } from "@mui/material";

const Page5 = () => {
  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={0}
          sm={5}
          lg={5}
          sx={{ display: { xs: "none", sm: "block", lg: "block" } }}
        >
          <Box
            sx={{
              background: "linear-gradient(to right, #044A67, #0a6b90)",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              height: "100vh",
              alignItems: "center",
            }}
          >
            <img
              src={img5}
              alt="mobile"
              height="65%"
              style={{ marginTop: "30px" }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          sm={7}
          lg={7}
          sx={{
            display: { xs: "block", sm: "flex", lg: "flex" },
            background: "#bde4f7",justifyContent:"center",alignItems:"center"
          }}
        >
          <Box
            sx={{
              // marginTop: "125px",
              textAlign: "justify",
              padding: "0 140px 30px 45px",
              position: "relative",
            }}
          >
            <Box
                  sx={{
                    position: "absolute",
                    top: "119px",
                    right: "249px",
                    width: "100px",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    opacity: "0.5",
                    fontSize: "100px",
                    color: "#b2abab61",
                    fontWeight: "600",
                    textAlign: "center",
                    fontSize:"800px"
                  }}
            >
              5
            </Box>
            <Typography
              style={{ fontSize: "26px", fontFamily: "CustomFontMedium" }}
            >
              How It Works?
            </Typography>
            <Typography
              style={{
                fontSize: "20px",
                // marginTop: "16px",
                color: "#8c8080",
                fontFamily: "CustomFontMedium",
                lineHeight: "normal",
              }}
            >
              At Due Factory we help you bring up your sub-optimal credit score
              which is a result of your financial history. Missed EMIs on a
              previous loan? Forgot to pay your CC bill months ago? Having
              difficulty in getting a new loan?
            </Typography>
            <Box style={{ marginTop: "60px" }}>
              <Typography
                style={{
                  fontSize: "40px",
                  color: "#27498b",
                  fontWeight: "700",
                  fontFamily: "CustomFontMedium",
                }}
              >
                Make payments
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  // marginTop: "16px",
                  color: "#8c8080",
                  fontFamily: "CustomFontMedium",
                  lineHeight: "normal",
                }}
              >
                Once You Are All Set To Make Payments , We Help Facilitate All
                Your Payments.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Page5;
